import React, {
  Suspense,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_PLANS, CREATE_SUBSCRIPTION, GET_ANALYTICS } from './subscription.gql';
import { GET_USER } from 'context/Auth/auth.gql';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

const UpgradeDialog = React.lazy(() => import('./UpgradeDialog'));

const SubscriptionContext = React.createContext();

export function useSubscription() {
  return useContext(SubscriptionContext);
}

export default function SubscriptionProvider({ children }) {
  const user = useSelector((state) => state.auth.user);

  const subscriptions = user?.subscriptions
    ?.filter(({ status }) => status === 'active')
    ?.sort((a, b) => b.plan.priority - a.plan.priority);

  const start_date = dayjs().startOf('month').format('YYYY-MM-DD');
  const end_date = dayjs().endOf('month').format('YYYY-MM-DD');
  const { data: analyticsData, loading: loadingAnalytics } = useQuery(GET_ANALYTICS, {
    variables: {
      start_date_lives: start_date,
      start_date_videos: start_date,
      end_date_lives: end_date,
      end_date_videos: end_date,
    },
  });
  const videoAnalytics = analyticsData?.analytics_videos || [];
  const video_views = videoAnalytics.reduce((acc, curr) => acc + curr.views, 0);

  const liveAnalytics = analyticsData?.lives || [];
  const live_views = liveAnalytics.reduce((acc, curr) => acc + curr.analytics?.views, 0);

  const analytics = loadingAnalytics
    ? null
    : {
        video_views,
        live_views,
      };

  const { data: plansData, loading } = useQuery(GET_PLANS);
  const plans = [...(plansData?.plans || [])].sort((a, b) => a.priority - b.priority);

  const [createSubscription] = useMutation(CREATE_SUBSCRIPTION);

  const createDefaultSubscription = useCallback(async () => {
    if (subscriptions.length === 0 && plans?.length > 0) {
      const plan = plans.find((plan) => plan.default);

      try {
        await createSubscription({
          variables: {
            input: {
              user_uid: user.uid,
              plan_uid: plan.uid,
              status: 'active',
            },
          },
          refetchQueries: [{ query: GET_USER }],
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [subscriptions, plans, user]);

  useEffect(() => {
    if (subscriptions?.length === 0 && plans?.length > 0) {
      createDefaultSubscription();
    }
  }, [subscriptions, plans]);

  const [contextPlanUid, setContextPlanUid] = useState(null);

  const currentSubscription = useMemo(() => {
    if (!subscriptions || subscriptions?.length === 0) {
      return null;
    }

    const subscription = subscriptions[0];

    if (contextPlanUid) {
      return {
        ...subscription,
        plan_uid: contextPlanUid,
      };
    }

    return subscription;
  }, [subscriptions, contextPlanUid]);

  const currentPlan = useMemo(() => {
    if (!currentSubscription || !plans) {
      return null;
    }

    return plans.find((plan) => plan.uid === currentSubscription.plan_uid);
  }, [currentSubscription, plans, contextPlanUid]);

  const [upgradeDialog, setUpgradeDialog] = useState(false);

  const isVideoAvailable =
    currentPlan?.video &&
    (currentPlan.views_video === 0 || video_views < currentPlan.views_video);
  const isLiveAvailable =
    currentPlan?.live &&
    (currentPlan.views_live === 0 || live_views < currentPlan.views_live);

  const value = {
    loading,
    isVideoAvailable,
    isLiveAvailable,
    analytics,
    plans,
    subscriptions,
    currentPlan,
    currentSubscription,
    upgradeDialog,
    setUpgradeDialog,
    setContextPlanUid,
  };

  const upgradeElement = upgradeDialog ? (
    <Suspense fallback={null}>
      <UpgradeDialog isOpen={upgradeDialog} handleClose={() => setUpgradeDialog(false)} />
    </Suspense>
  ) : null;

  return (
    <SubscriptionContext.Provider value={value}>
      {upgradeElement}
      {children}
    </SubscriptionContext.Provider>
  );
}
