import { auth } from 'services/firebase';
import React, { useContext, useEffect, useCallback } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_USER, LOGIN, REGISTER, FORGOT_PASSOWRD, GOOGLE_SIGN_IN } from './auth.gql';
import { useDispatch } from 'react-redux';
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice';
import { setUser, initialState } from 'store/auth/userSlice';
import { useNavigate } from 'react-router-dom';
import useQueryParams from 'utils/hooks/useQuery';
import { parseGraphQLError } from 'utils/errors';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useSelector } from 'react-redux';
import { REDIRECT_URL_KEY } from 'constants/app.constant';
import appConfig from 'configs/app.config';
import { USER } from 'constants/roles.constant';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQueryParams();

  const { signedIn, token } = useSelector((state) => state.auth.session);
  const user = useSelector((state) => state.auth.user);

  const [getUser, { data, loading, error, called }] = useLazyQuery(GET_USER, {
    skip: !localStorage.getItem('token'),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const [login] = useMutation(LOGIN);
  const [googleSignIn] = useMutation(GOOGLE_SIGN_IN);
  const [register] = useMutation(REGISTER);
  const [forgotPassword] = useMutation(FORGOT_PASSOWRD);

  const configAuth = async (token, user) => {
    dispatch(onSignInSuccess(token));
    localStorage.setItem('token', token);
    dispatch(setUser({ ...user, authority: [USER] }));

    const redirectUrl = query.get(REDIRECT_URL_KEY);
    navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
  };

  const handleSignUp = async ({
    email,
    password,
    firstname,
    lastname,
    telephone,
    company,
  }) => {
    try {
      const { data } = await register({
        variables: {
          input: {
            email,
            password,
            firstname,
            lastname,
            telephone,
            company,
          },
        },
      });
      const { access_token } = data?.register;
      await configAuth(access_token, data?.register);
    } catch (error) {
      dispatch(onSignOutSuccess());
      localStorage.removeItem('token');
      return parseGraphQLError(error);
    }
  };

  const handleSignIn = async ({ email, password }) => {
    try {
      const { data } = await login({
        variables: {
          input: {
            email,
            password,
          },
        },
      });
      const { access_token } = data?.login;
      await configAuth(access_token, data?.login);
    } catch (error) {
      dispatch(onSignOutSuccess());
      localStorage.removeItem('token');
      return parseGraphQLError(error);
    }
  };

  const handleGoogleSignIn = useCallback(async () => {
    const provider = new GoogleAuthProvider();

    try {
      const { user } = await signInWithPopup(auth, provider);
      if (user) {
        const { accessToken, uid, displayName, email, photoURL } = user;
        const { data } = await googleSignIn({
          variables: {
            input: {
              access_token: accessToken,
              uid,
              display_name: displayName,
              email,
              photo_url: photoURL,
            },
          },
        });
        const { access_token } = data?.googleSignIn;
        await configAuth(access_token, data?.googleSignIn);
      }
    } catch (error) {
      console.error(error);
    }
  }, [user]);

  const handleForgotPassword = async ({ email }) => {
    try {
      const { data } = await forgotPassword({
        variables: {
          email,
        },
      });
      return data?.forgotPassword;
    } catch (error) {
      return parseGraphQLError(error);
    }
  };

  const handleSignOut = async () => {
    dispatch(onSignOutSuccess());
    setUser(initialState);
    localStorage.removeItem('token');
  };

  useEffect(() => {
    if (data?.users?.length > 0) {
      dispatch(setUser({ ...data.users[0], authority: [USER] }));
    }
  }, [signedIn, token, data]);

  useEffect(() => {
    if (signedIn && token && !called) {
      getUser();
    }
  }, [signedIn, token, called]);

  useEffect(() => {
    if (error) {
      dispatch(onSignOutSuccess());
      localStorage.removeItem('token');
      window.location.reload();
    }
  }, [error]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      const localToken = localStorage.getItem('token');

      if (user) {
        const { refreshToken, accessToken } = user;
        localStorage.setItem('refresh_token', refreshToken);

        if (accessToken && accessToken !== localToken) {
          localStorage.setItem('token', user.accessToken);
        }
      }
    });

    return unsubscribe;
  }, []);

  const value = {
    loading,
    authenticated: signedIn && user,
    user,
    getUser,
    setUser,
    handleSignIn,
    handleSignUp,
    handleSignOut,
    handleForgotPassword,
    handleGoogleSignIn,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
