import { gql } from '@apollo/client';

export const GET_STORE = gql`
  query getStore {
    stores {
      uid
      site_url
      installed
      verified
      currency_code
    }
  }
`;
