import { gql } from '@apollo/client';

export const GET_PLANS = gql`
  query getPlans {
    plans {
      uid
      enable
      default
      name
      features
      price
      stripe_subs_price_id
      contact
      priority

      views
      views_video
      views_live
      leads
      storage
      api
      live
      video
      watermark
    }
  }
`;

export const CREATE_SUBSCRIPTION = gql`
  mutation createSubscription($input: subscriptions_insert_input!) {
    insert_subscriptions_one(object: $input) {
      uid
    }
  }
`;

export const GET_ANALYTICS = gql`
  query subscriptionAnalytics(
    $start_date_lives: timestamptz!
    $start_date_videos: date!
    $end_date_lives: timestamptz!
    $end_date_videos: date!
  ) {
    lives(where: { created_at: { _gte: $start_date_lives, _lte: $end_date_lives } }) {
      uid
      created_at
      analytics {
        conversions
        uid
        likes
        views
      }
    }

    analytics_videos(
      where: { date: { _gte: $start_date_videos, _lte: $end_date_videos } }
    ) {
      uid
      video_uid
      page_url
      media_uid
      date
      views
      likes
      unique_views
      product_clicks
      cart_clicks
    }
  }
`;
