import React from 'react';
import { Button, Dialog } from 'components/ui';
import { Trans } from 'react-i18next';

const DeletePageDialog = ({ isOpen, handleClose, page, handleDeletePage, loading }) => {
  const handleDelete = async () => {
    try {
      await handleDeletePage(page);
      handleClose();
    } catch (error) {
      return;
    }
  };

  return (
    <Dialog
      width={620}
      isOpen={isOpen}
      onClose={handleClose}
      onRequestClose={handleClose}
    >
      <div className="text-center mt-12 mb-12">
        <h3 className="mb-2">{'Apagar página'}</h3>
        {page?.url && <p className="break-all	">{page.url}</p>}
        <p>
          <Trans
            i18nKey={'pages.deletePageMessage'}
            defaults={'Are you sure you want to delete the URL?'}
          />
        </p>
        <p>
          <Trans
            i18nKey={'pages.actionCannotBeUndone'}
            defaults={'This action cannot be undone'}
          />
        </p>
      </div>

      <div className="flex justify-center gap-4 mt-2 mb-4">
        <Button onClick={handleClose}>
          <Trans i18nKey={'global.cancel'} defaults={'Cancel'} />
        </Button>
        <Button type="button" variant="solid" loading={loading} onClick={handleDelete}>
          <Trans i18nKey={'global.confirm'} defaults={'Confirm'} />
        </Button>
      </div>
    </Dialog>
  );
};

export default DeletePageDialog;
