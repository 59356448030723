import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      access_token
      id
      uid
      email
      firstname
      lastname
      telephone
      company
      avatar
      error {
        code
        message
      }
    }
  }
`;

export const REGISTER = gql`
  mutation register($input: RegisterInput!) {
    register(input: $input) {
      access_token
      id
      uid
      email
      firstname
      lastname
      telephone
      error {
        code
        message
      }
    }
  }
`;

export const GET_USER = gql`
  query getUser {
    users {
      uid
      id
      firstname
      lastname
      email
      telephone
      avatar
      company
      subscriptions {
        uid
        status
        payment
        last_order_id
        plan_uid
        created_at
        updated_at
        plan {
          uid
          name
          priority
        }
      }
    }
  }
`;

export const FORGOT_PASSOWRD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      success
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($input: users_set_input!) {
    update_users(where: {}, _set: $input) {
      returning {
        uid
        id
        firstname
        lastname
        email
        telephone
        avatar
      }
    }
  }
`;

export const GOOGLE_SIGN_IN = gql`
  mutation googleSignIn($input: GoogleSignInInput!) {
    googleSignIn(input: $input) {
      access_token
      uid
      email
      firstname
      lastname
      avatar
      telephone
      company
    }
  }
`;

export const GET_PLANS = gql`
  query getPlans {
    plans {
      uid
      name
      views
      leads
      storage
      enable
      price
      features
      contact
      stripe_subs_price_id
    }
  }
`;
